import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Button, ButtonColor, RenderType } from '@jsmdg/yoshi';
import { trackNewsLetterSubscription } from '../../helper/trackingFooter';
import { type FooterContext } from '../../types';
import styles from './Newsletter.module.scss';

const Newsletter = (): JSX.Element => {
    const { isTrustpilotEnabled, tenantConfig }: FooterContext = useFragmentContext();
    const buildNewsletterSubscribeUrl = (label: string): string =>
        `${tenantConfig.urls.newsletter}?source=${encodeURIComponent(
            label,
        )}&label=${encodeURIComponent(label)}`;

    const handleClick = (e: React.MouseEvent): void => {
        e.preventDefault();
        const url = buildNewsletterSubscribeUrl('Footer');
        trackNewsLetterSubscription(url);
        window.location.href = url;
    };

    return (
        <div
            className={classNames(
                styles.newsletterBox,
                'px-3x px-sm-6x py-2x py-sm-4x d-sm-flex justify-content-sm-center align-items-sm-center text-center',
                {
                    'mt-3x': !isTrustpilotEnabled,
                },
            )}
            data-testid="newsletterBox"
        >
            <FormattedMessage
                defaultMessage="Newsletter abonnieren und {discountValue} Rabatt sichern"
                values={{
                    discountValue: tenantConfig.newsletter.discount,
                }}
            >
                {msg => (
                    <p className="p-0 mr-sm-4x mb-2x mb-sm-0 font-complementary fst-normal fw-semibold">
                        {msg}
                    </p>
                )}
            </FormattedMessage>
            <Button
                as={RenderType.Link}
                href={buildNewsletterSubscribeUrl('Footer')}
                className="border-0"
                color={ButtonColor.Complementary}
                onClick={handleClick}
                internal
            >
                <FormattedMessage defaultMessage="Abonnieren" />
            </Button>
        </div>
    );
};

export { Newsletter };
