import {
    AmazonPayLogoSrc,
    AmexLogoSrc,
    KlarnaSrc,
    MastercardLogoSrc,
    PayPalLogoSrc,
    VisaSrc,
} from '@jsmdg/yoshi';
import { PaymentMethod } from '../../../shared/enums/paymentMethods';

export const paymentMethodLogoMap = {
    [PaymentMethod.Paypal]: { alt: 'PayPal', icon: PayPalLogoSrc },
    [PaymentMethod.AmazonPay]: { alt: 'Amazon Pay', icon: AmazonPayLogoSrc },
    [PaymentMethod.Amex]: { alt: 'American Express', icon: AmexLogoSrc },
    [PaymentMethod.Mastercard]: { alt: 'Master Card', icon: MastercardLogoSrc },
    [PaymentMethod.Visa]: { alt: 'Visa', icon: VisaSrc },
    [PaymentMethod.Klarna]: { alt: 'Klarna', icon: KlarnaSrc },
};
