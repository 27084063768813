import { useContext } from 'react';
import { IntlContext } from 'react-intl';
import classNames from 'classnames';

import { ChevronIcon, Link, LinkVariant } from '@jsmdg/yoshi';
import styles from './HelpLink.module.scss';

type Props = {
    readonly href: string;
    readonly message: { id?: string; defaultMessage: string };
};

const HelpLink = ({ href, message }: Props): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { formatMessage } = useContext(IntlContext);

    return (
        <Link
            variant={LinkVariant.GreyQuiet}
            href={href}
            className={classNames('d-inline-flex align-items-center', styles.link)}
            internal
        >
            <ChevronIcon size={18} className={styles.chevron} />
            <span>{formatMessage(message)}</span>
        </Link>
    );
};

export { HelpLink };
